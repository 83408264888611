import styled from "styled-components";
import { Game, Spinner } from "./selectGame";
import GameListItem from "./gameListItem";
import Modal from "react-modal";
import { useState } from "react";
import PrimaryButton from "../components/PrimaryButton";
import SecondaryButton from "../components/SecondaryButton";
import WhiteButton from "../components/WhiteButton";
import axios from "axios";
import randomstring from 'randomstring';
import one from '../images/upgrades/1.png';
import two from '../images/upgrades/2.png';
import three from '../images/upgrades/3.png';
import four from '../images/upgrades/4.png';
import five from '../images/upgrades/5.png';
import six from '../images/upgrades/6.png';
import seven from '../images/upgrades/7.png';
import eight from '../images/upgrades/8.png';
import NO from '../images/flags/NO.png'
import DE from '../images/flags/DE.png'

interface HomeProps {
    games: Game[] | undefined,
    userid: String,
    username: String,
    newGame: () => void,
    discover: () => void,
    startGame: (gameId: String, gameInstanceId: String, gamePin: String) => void,
    loading: boolean,
    error: boolean,
    edit: (id: String) => void;
}

const Home = ({ userid, username, games, newGame, discover, loading, startGame, error, edit}: HomeProps) => {

  const [modalIsOpen, setModalIsOpen] = useState(false);
	const [selectedGameId, setSelectedGameId] = useState<String>();

	const [watingForCreateGame, setWaitingForCreateGame] = useState(false);
	const [newGamePin, setNewGamePin] = useState('');

  const [selectedItems, setSelectedItems] = useState([0,1]);

	const createNewGameInstance = (gameId: String, gamePin: String) => {
		setWaitingForCreateGame(true);
			axios.post(`https://www.dogetek.no/api/api.php/game_instance/`, {
				game_id: gameId,
				game_pin: gamePin,
				status: 'created',
				created_by: userid
			}, { headers: { 'content-type': 'application/x-www-form-urlencoded' } })
				.then(res => {
					console.log('gameInstanceId:', res.data);

					setWaitingForCreateGame(false);
					//setPlayGames(selectedGameId || '')
					startGame(gameId, res.data, gamePin);
				})
				.catch(err => {
					console.log("Something fishy is going on");
					//seterror(true);
					setWaitingForCreateGame(false);
				});

	}

    return (
        <>
        <Header>
          <Welcome>Welcome, {username}!</Welcome>
          <Actions>
            <WhiteButton click={newGame} text="New quiz"></WhiteButton>
          </Actions>
        </Header>
        
          <Recent>Your games</Recent>
          <RecentItems>
          { loading ? 
              <Spinner/> 
            :
              <>
                {error && <div>An error has occured</div>}
                {games?.map(game => <GameListItem onClick={() => {setSelectedGameId(game.id); setModalIsOpen(true); setSelectedItems([0,1])}} edit={edit} game={game}/>)}
              </>
          }
          </RecentItems>
          <Modal
        isOpen={modalIsOpen}
        onAfterOpen={() => {
					setNewGamePin(randomstring.generate({
						charset: ['numeric'],
						readable: true,
						length: 5
					}))
				}}
        onRequestClose={() => {setModalIsOpen(false); setSelectedItems([0,1])}}
        style={{
					overlay: {
						position: 'fixed',
						top: 0 + '82px',
						left: 0,
						right: 0,
						bottom: 0,
						backgroundColor: 'rgba(255, 255, 255, 0.75)'
					},
					content: {
						top: '40%',
						left: '50%',
						right: 'auto',
						bottom: 'auto',
						marginRight: '-50%',
						transform: 'translate(-50%, -50%)',
						borderRadius: '15px',
						border: 'none',
						color: 'white',
						fontFamily: "Coll",
						backgroundImage: 'linear-gradient(180deg, #6A71FA 0%, #9C8AFA 100%)',
						width: '70%',
						maxWidth: '1000px',
            minWidth: '850px'
					},
				}}
        contentLabel="Game options"
      >
				<Content>
					<Left>
						<Heading>{games?.find(game => game.id == selectedGameId)?.name}</Heading>
						<Username>by {games?.find(game => game.id == selectedGameId)?.username}</Username>
						<div style={{ paddingLeft: '20px' }}>{games?.find(game => game.id == selectedGameId)?.qcount} question{ games?.find(game => game.id == selectedGameId)?.qcount !== '1' && 's' }</div>
						<div style={{ position: 'absolute', bottom: '40px', left: '40px' }}>
              <WhiteButton text="Edit game" click={() => edit(selectedGameId || '')} />
            </div>
					</Left>
					<Right>
            <div>
              <GrayHeading>Choose available upgrades:</GrayHeading>
              <Row>
                <SmallItemImage src={one} onClick={() => {selectedItems.includes(0) ? setSelectedItems(selectedItems.filter(item => item !== 0)) : setSelectedItems([...selectedItems, 0])}} selected={selectedItems.includes(0)}/>
                <SmallItemImage src={two} onClick={() => {selectedItems.includes(1) ? setSelectedItems(selectedItems.filter(item => item !== 1)) : setSelectedItems([...selectedItems, 1])}} selected={selectedItems.includes(1)}/>
                <SmallItemImage src={three} onClick={() => {selectedItems.includes(2) ? setSelectedItems(selectedItems.filter(item => item !== 2)) : setSelectedItems([...selectedItems, 2])}} selected={selectedItems.includes(2)}/>
                <SmallItemImage src={four} onClick={() => {selectedItems.includes(3) ? setSelectedItems(selectedItems.filter(item => item !== 3)) : setSelectedItems([...selectedItems, 3])}} selected={selectedItems.includes(3)}/>
              </Row>
              <Row>
                <SmallItemImage src={five} onClick={() => {selectedItems.includes(4) ? setSelectedItems(selectedItems.filter(item => item !== 4)) : setSelectedItems([...selectedItems, 4])}} selected={selectedItems.includes(4)}/>
                <SmallItemImage src={six} onClick={() => {selectedItems.includes(5) ? setSelectedItems(selectedItems.filter(item => item !== 5)) : setSelectedItems([...selectedItems, 5])}} selected={selectedItems.includes(5)}/>
                <SmallItemImage src={seven} onClick={() => {selectedItems.includes(6) ? setSelectedItems(selectedItems.filter(item => item !== 6)) : setSelectedItems([...selectedItems, 6])}} selected={selectedItems.includes(6)}/>
                <SmallItemImage src={eight} onClick={() => {selectedItems.includes(7) ? setSelectedItems(selectedItems.filter(item => item !== 7)) : setSelectedItems([...selectedItems, 7])}} selected={selectedItems.includes(7)}/>
              </Row>
            </div>
            <div>
              <GrayHeading>Select available server:</GrayHeading>
              <LangRow>
                <LanguageImage src={DE} onClick={() => {}} selected={true} />
                  <div>
                    <City>Frankfurt (selected)</City>
                    <Status online>Good</Status>
                  </div>
              </LangRow>
              <LangRow>
                <LanguageImage src={NO} onClick={() => {}} selected={false} />
                  <div>
                    <City>Oslo</City>
                    <Status>Disconnected</Status>
                  </div>
              </LangRow>
            </div>
            <div>
              <GrayHeading>Game pin:</GrayHeading>
              <NewPin>{newGamePin}</NewPin>
            </div>
						<div style={{ paddingBottom: '10px', paddingTop: '10px', }}>
              <PrimaryButton text="Start game" click={() => createNewGameInstance(selectedGameId || '0', newGamePin)} loading={watingForCreateGame} />
            </div>
					</Right>
				</Content>
      </Modal>
          </>
    );
};

export default Home;

const City = styled.div`
	color: #000000;
	font-family: "Coll";
	font-size: 1rem;
	padding-top: 10px;
  padding-left: 0px;
`;


const Status = styled.div.attrs((props: {online: boolean}) => props)`
	color: #ffffff;
  background: ${props => props.online ? '#5DB868' : '#BEBEBE'};
  border-radius: 5px;
  padding: 2px;
  width: 120px;
  text-align: center;
	font-family: "Coll";
	font-size: 0.8rem;
	margin-top: 10px;
`;

const LanguageImage = styled.img.attrs((props: {selected: boolean}) => props)`
    display: block;
    
    margin-bottom: 10px;
    height: 70px;
    margin-right: 10px;
    cursor: pointer;

    opacity: ${props => props.selected ? '100%' : '30%'};
`;

const LangRow = styled.div`
	display: flex;
  padding-top: 10px;
  padding-left: 20px;
  justify-content: left;
`;

const Row = styled.div`
	display: flex;
  padding-top: 10px;
  justify-content: center;
`;

const GrayHeading = styled.div`
	color: #9f9f9f;
	font-family: "Coll";
	font-size: 1rem;
	padding-top: 10px;
  padding-left: 20px;
`;

const SmallItemImage = styled.img.attrs((props: {selected: boolean}) => props)`
    display: block;
    
    margin-bottom: 10px;
    height: 50px;
    margin-right: 10px;
    cursor: pointer;

    opacity: ${props => props.selected ? '100%' : '30%'};
`;

const Heading = styled.div`
	color: white;
	font-family: "Coll";
	font-size: 2.5rem;
  padding-top: 20px;
  padding-left: 20px;
`;

const Left = styled.div`
	display: block;
	width: 65%;
`;

const Right = styled.div`
	display: block;
	width: 35%;
	margin: 20px;
	border-radius: 15px;
	background: white;
`;

const Content = styled.div`
	display: flex;
`;

const NewPin = styled.div`
	color: #9f9f9f;
	font-family: "Coll";
	font-size: 2.5rem;
	text-align: center;
	padding-top: 10px;
`;

const Header = styled.div`
    color: white;
    font-family: "Coll";
    padding: 30px;
    background-image: linear-gradient(180deg, #6A71FA 0%, #9C8AFA 100%);
  width: 100%;
`;

const Username = styled.div`
	color: #ffffff90;
	font-family: "Coll";
	font-size: 1.5rem;
	padding-left: 20px;
`;

const RecentItems = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const New = styled.div`
  background: #2d3870;
  margin: 0px;
  border-radius: 5px;
  padding: 8px;
  width: 120px;
  height: 30px;
  font-weight: bold;
cursor: pointer;
  color: white;
  border: 2px solid #2d3870;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  &:hover {
    border: 2px solid #2d387050;
    background: #2d387050;
  }
`;

const Discover = styled.div`
  background: #ffffff;
  border: 2px solid #2d3870;
  margin: 0px;
  border-radius: 5px;
  padding: 8px;
  width: 120px;
  height: 30px;
  font-weight: bold;
  cursor: pointer;
  color: #2d3870;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    border: 2px solid #2d387050;
  }
`;

const Actions = styled.div`
  display: flex;
  padding: 31px;
  padding-top: 5px;
`;

const Welcome = styled.div`
  font-size: 2.5rem;
  font-family: sans-serif;
  padding: 31px;
  font-family: "Coll";
`;

const Recent = styled.div`
  font-size: 1.5rem;
  color: #05212f70;
  font-family: sans-serif;
  padding: 31px;
  padding-bottom: 0px;
  font-family: "Coll";
`;
